import { message } from "antd";
import { IConfigSafra, IConfigSafraList } from "../reducers/ConfigSafraReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_CONFIG_SAFRA, SET_LIST_CONFIG_SAFRA } from "./actionTypes";

//Métodos para administração de Configuração de Safra
export const setConfigSafra = (value: IConfigSafra) => ({
    type: SET_CONFIG_SAFRA,
    payload: value,
});

export const setListConfigSafra = (value: IConfigSafraList) => ({
    type: SET_LIST_CONFIG_SAFRA,
    payload: value,
});

export const getListConfigSafra = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "configSafra"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/configuracao_safra/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "configSafra", res.erro));
                } else {
                    dispatch(setListConfigSafra(res));
                    dispatch(setStatusSuccess("get", "configSafra"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "configSafra", error.data));
            });
    };
};

interface IAddConfigSafra {
    data_inicial_manejo: string;
    data_final_manejo: string;
    data_inicial_colheita: string;
    data_final_colheita: string;
    talhao_temp: string;
    safra_temp: string;
    cultura_temp: string;
}

export const adicionarConfigSafra = (value: IAddConfigSafra) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "configSafra"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/configuracao_safra/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "configSafra", res.erro));
                } else {
                    dispatch(getListConfigSafra());
                    dispatch(setStatusSuccess("add", "configSafra"));
                    message.success("Configuração adicionada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "configSafra", error.data));
            });
    };
};

interface IEditConfigSafra {
    id_configuracao_safra: string;
    data_inicial_manejo: string;
    data_final_manejo: string;
    data_inicial_colheita: string;
    data_final_colheita: string;
    talhao_temp: string;
    safra_temp: string;
    cultura_temp: string;
}
export const editarConfigSafra = (value: IEditConfigSafra) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "configSafra"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/configuracao_safra/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "configSafra", res.erro));
                } else {
                    dispatch(getListConfigSafra());
                    dispatch(setStatusSuccess("edit", "configSafra"));
                    message.success("Configuração editada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "configSafra", error.data));
            });
    };
};

interface IDeletConfigSafra {
    value: string;
}
export const deletarConfigSafra = (value: IDeletConfigSafra) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "configSafra"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/configuracao_safra/` +
                value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "configSafra", res.erro));
                } else {
                    dispatch(getListConfigSafra());
                    dispatch(setStatusSuccess("del", "configSafra"));
                    message.success("Configuração excluída com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "configSafra", error.data));
            });
    };
};
