import { bindActionCreators } from "redux";
import { IStore } from "../../../store";
import { connect } from "react-redux";
import { IFazenda, IFazendaList } from "../../../reducers/FazendaReducer";
import { Button, Modal, Popconfirm, Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ITheme } from "../../../reducers/ThemeReducer";
import AdicionarConfigSafra from "./ConfigSafra/AdicionarConfigSafra";
import { IStatusList, ITalhaoTablet } from "../../../store/types";
import {
    deletarConfigSafra,
    getListConfigSafra,
} from "../../../store/configSafraActions";
import {
    IConfigSafra,
    IConfigSafraList,
} from "../../../reducers/ConfigSafraReducer";
import { ISafra, ISafraList } from "../../../reducers/SafraReducer";
import { ICultura, ICulturaList } from "../../../reducers/CulturaReducer";
import EditarConfigSafra from "./ConfigSafra/EditarConfigSafra";
import { setStatusNull } from "../../../store/actions";

const { Option, OptGroup } = Select;

interface IConfigSafraProps {
    fazendas: IFazendaList;
    theme: ITheme;
    talhoes: ITalhaoTablet[];
    configSafraList: IConfigSafraList;
    safras: ISafraList;
    culturas: ICulturaList;

    status: IStatusList;

    getListConfigSafra: Function;
    deletarConfigSafra: Function;
    setStatusNull: any;
}

function ConfigSafra(props: IConfigSafraProps) {
    const {
        fazendas,
        theme,
        talhoes,
        getListConfigSafra,
        configSafraList,
        culturas,
        safras,
        deletarConfigSafra,
        status,
        setStatusNull,
    } = props;

    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [talhaoLocal, setTalhaoLocal] = useState<any>("");
    const [which, setWhich] = useState<any>(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Configuração",
            conteudo: <AdicionarConfigSafra setModal={setModal} />,
        });
        setModal(true);
    }

    function handleEditar(value: any) {
        const configIndex = configSafraList.findIndex(
            (c: IConfigSafra) => c.id_configuracao_safra === value
        );

        setConfigModal({
            titulo: "Editar Configuração",
            conteudo: (
                <EditarConfigSafra
                    cont={configSafraList[configIndex]}
                    setModal={setModal}
                />
            ),
        });
        setModal(true);
    }

    function handleSelectTalhao(value: any) {
        setTalhaoLocal(value);
        setWhich(0);
    }

    function handleExcluir() {
        setConfirmLoading(true);
        deletarConfigSafra(which);
    }

    const listaTalhao = useCallback(() => {
        return fazendas
            .filter((fazenda: IFazenda) => fazenda.shape !== "")
            .map((fazenda: IFazenda) => ({
                fazenda,
                talhoes: JSON.parse(fazenda.shape).features.map(
                    (f: any) => f.properties
                ),
            }));
    }, [fazendas]);

    useMemo(() => {
        getListConfigSafra();
    }, []);

    useMemo(() => {
        if (status.configSafra?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "alerta");
        }
        getListConfigSafra();
        setWhich(0);
    }, [getListConfigSafra, setStatusNull, status.configSafra?.del?.success]);

    return (
        <>
            <div
                style={{
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                    }}
                >
                    <div
                        style={{
                            paddingRight: "10px",
                        }}
                    >
                        Config. cadastrada para talhão:
                    </div>
                    <div>
                        <Select
                            style={{
                                width: "200px",
                            }}
                            size="small"
                            onSelect={handleSelectTalhao}
                            showSearch
                            filterOption={(input, option: any) =>
                                option.children &&
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {fazendas.map(fazenda => (
                                <OptGroup
                                    key={fazenda.id_fazenda}
                                    label={"faz. " + fazenda.nome}
                                >
                                    {talhoes
                                        .filter(
                                            (talhao: any) =>
                                                talhao.fazenda_temp ===
                                                fazenda.id_fazenda
                                        )
                                        .sort((a: any, b: any) => {
                                            return (
                                                a.nome_talhao - b.nome_talhao
                                            );
                                        })
                                        .map((t: any) => (
                                            <Option
                                                key={t.id_talhao}
                                                value={t.id_talhao}
                                            >
                                                {t.nome_talhao}
                                            </Option>
                                        ))}
                                </OptGroup>
                            ))}
                        </Select>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {configSafraList.map(config => {
                                if (config.talhao_temp === talhaoLocal) {
                                    let classN = theme
                                        ? "select-expand-li-dark"
                                        : "select-expand-li";
                                    if (
                                        config.id_configuracao_safra === which
                                    ) {
                                        classN += " active";
                                    }
                                    return (
                                        <li
                                            className={classN}
                                            key={config.id_configuracao_safra}
                                            value={config.id_configuracao_safra}
                                            onClick={() =>
                                                setWhich(
                                                    config.id_configuracao_safra
                                                )
                                            }
                                            onDoubleClick={() =>
                                                handleEditar(
                                                    config.id_configuracao_safra
                                                )
                                            }
                                            style={{
                                                display: "inline-block",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Safra:{" "}
                                                    {
                                                        safras[
                                                            safras.findIndex(
                                                                (s: ISafra) =>
                                                                    s.id_safra ===
                                                                    config.safra_temp
                                                            )
                                                        ].descricao
                                                    }
                                                </div>
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Cultura:{" "}
                                                    {
                                                        culturas[
                                                            culturas.findIndex(
                                                                (c: ICultura) =>
                                                                    c.id_cultura ===
                                                                    config.cultura_temp
                                                            )
                                                        ].nomeCultura
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Data Inicio Manejo:{" "}
                                                    {config.data_inicial_manejo !==
                                                        undefined &&
                                                        new Date(
                                                            config.data_inicial_manejo
                                                        ).toLocaleDateString()}
                                                </div>
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Data Fim Manejo:{" "}
                                                    {config.data_final_manejo !==
                                                        undefined &&
                                                        new Date(
                                                            config.data_final_manejo
                                                        ).toLocaleDateString()}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Data Inicio Colheita:{" "}
                                                    {config.data_inicial_colheita !==
                                                        undefined &&
                                                        new Date(
                                                            config.data_inicial_colheita
                                                        ).toLocaleDateString()}
                                                </div>
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Data Fim Colheita:{" "}
                                                    {config.data_final_colheita !==
                                                        undefined &&
                                                        new Date(
                                                            config.data_final_colheita
                                                        ).toLocaleDateString()}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                            })}

                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich(0)}
                            ></li>
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir a configuração selecionada?"
                            onConfirm={handleExcluir}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                disabled={which === 0 ? true : false}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        theme: store.theme,
        talhoes: store.talhoesTablet,
        safras: store.safras,
        culturas: store.culturas,

        configSafraList: store.configSafras,

        status: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getListConfigSafra,
            deletarConfigSafra,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSafra);
