import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "./store";
import styled from "styled-components";
import { useEffect, useState, useCallback } from "react";
import { IEquipamento, IEquipamentoList } from "./reducers/EquipamentoReducer";
import { IFoco, ITalhaoTablet } from "./store/types";
import { Popover, Tooltip } from "antd";
import { IProcess } from "./reducers/ProcessReducer";
import { IFazenda } from "./reducers/FazendaReducer";
import { geoContains } from "d3-geo";
import { v4 as uuidv4 } from "uuid";
import { display, margin } from "@mui/system";

const MAX_VISIBLE_ITEMS = 7;

const EstiloLegenda = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    height: 150px;
    width: calc(100% - 40px);
    margin-left: 40px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 25%,
        rgba(0, 0, 0, 0.9) 50%,
        rgba(0, 0, 0, 1) 100%
    );
    z-index: 1000;
    flex-direction: row;
    column-gap: 10px;
    row-gap: 5px;
    justify-content: space-around;

    /* Estilos para telas menores */
    @media screen and (max-width: 991.98px) and (orientation: portrait) {
        width: calc(100% - 40px);

        /* Oculta os itens */
        & .hidden-item {
            display: none;
        }
    }

    /* Estilos para orientação landscape e telas menores */
    @media screen and (max-width: 991.98px) and (orientation: landscape) {
        height: auto;
        width: calc(100% - 40px);
        justify-content: flex-start;

        /* Oculta os itens */
        & .hidden-item {
            display: none;
        }
    }
`;
const LegendaItem = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;

    @media screen and (max-width: 991.98px) {
        flex: 1;
        padding: 0px 10px 10px;
        margin-top: 0px;
    }
`;
const LegendaTitulo = styled.div`
    font-size: 18px;
    margin-bottom: 5px;
    /* Estilos para orientação landscape e telas menores */
    @media screen and (max-width: 991.98px) and (orientation: landscape) {
        margin-bottom: 0;
    }
`;
const LegendaConteudo = styled.div`
    display: flex;
    justify-content: space-between;
`;

const LegendaCoresConteudo = styled.div`
    width: calc(100vw - 100px);
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    align-items: center;

    @media screen and (max-width: 991.98px) {
        width: calc(100vw - 42px);
        flex-direction: row;
        column-gap: 5px;
        row-gap: 5px;
    }
`;
const LegendaCoresItem = styled.div`
    width: 195px;
    height: 20px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    @media screen and (max-width: 991.98px) {
        width: 130px;
        padding: 0 2px;
    }
`;
const Bola = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color};
    height: 10px;
    width: 10px;
    margin-right: 3px;
`;
const ContainerBola = styled.div`
    display: flex;
    align-items: center;
`;

const MostrarMaisButton = styled.div`
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    text-align: center;
`;

interface PropsLegenda {
    equipamentos: IEquipamentoList;
    fazenda: IFazenda;
    foco: IFoco;

    polylineFiltro: any;
    processo: IProcess;
    setRefresher: any;

    talhoesFiltro: ITalhaoTablet[];
}

function Legenda(props: PropsLegenda) {
    const {
        equipamentos,
        fazenda,
        foco,
        polylineFiltro,
        processo,
        talhoesFiltro,
    } = props;

    // const [status, setStatus] = useState(Object);
    // const [estado, setEstado] = useState(Object);
    // const [operando, setOperando] = useState(Object);
    const [equipaCores, setEquipaCores] = useState([]);
    // const [equipaTalhoes, setEquipaTalhoes] = useState([]);

    const [expandido, setExpandido] = useState(false);

    const [screenValida, setScreenValida] = useState(
        window.screen.width < 991.98 && window.innerWidth > window.innerHeight
    );

    const [orientation, setOrientation] = useState(
        window.innerWidth > window.innerHeight
    );

    const handleMostrarMais = () => {
        setExpandido(!expandido);
    };

    const itensVisiveis = orientation
        ? equipaCores
        : equipaCores.slice(0, MAX_VISIBLE_ITEMS);

    const equipaFaz = useCallback(() => {
        return equipamentos.filter((e: IEquipamento) => {
            if (e.ultima_operacao !== undefined) {
                return e.ultima_operacao.fazenda_temp === fazenda.id_fazenda;
            } else {
                return false;
            }
        });
    }, [fazenda, equipamentos]);

    const status = useCallback(() => {
        let atual = new Date().getTime();
        let equipaFilter = equipamentos.filter((e: IEquipamento) => {
            if (e.ultima_operacao !== undefined) {
                return e;
            } else {
                return false;
            }
        });
        let statusOn = equipaFilter.filter((l: IEquipamento) => {
            return (
                l.ultima_operacao.motorON === true &&
                l.estado !== "-1" &&
                atual - new Date(l.ultima_operacao?.timestamp).getTime() <
                    360000
            );
        }).length;
        let statusOff = equipaFilter.filter(
            (e: IEquipamento) =>
                e.ultima_operacao.motorON === false && e.estado !== "-1"
        ).length;
        return {
            ligado: statusOn,
            desligado: statusOff,
            semInfo:
                equipaFilter.filter((l: IEquipamento) => {
                    return l.estado !== "-1";
                }).length -
                statusOn -
                statusOff,
        };
    }, [equipamentos]);

    const estado = useCallback(() => {
        let equipaFilter = equipamentos.filter((e: IEquipamento) => {
            if (e.ultima_operacao !== undefined) {
                return e;
            } else {
                return false;
            }
        });

        let ativos = equipaFilter.filter((a: IEquipamento) => {
            return a.estado === "1";
        });
        let inativos = equipaFilter.filter((i: IEquipamento) => {
            return i.estado === "-1";
        });
        return {
            ativo: ativos.length,
            inativo: inativos.length,
            manutencao: equipaFilter.length - (ativos.length + inativos.length),
        };
    }, [equipamentos]);

    const operando = useCallback(() => {
        let atual = new Date().getTime();
        let ope = equipaFaz().filter((o: IEquipamento) => {
            if (o.estado !== "-1") {
                if (
                    o.ultima_operacao.motorON &&
                    atual - new Date(o.ultima_operacao?.timestamp).getTime() <
                        360000
                ) {
                    if (o.ultima_operacao.velocidade !== undefined) {
                        return o.ultima_operacao.velocidade > 0;
                    } else {
                        return false;
                    }
                }
            }
        });
        let opeParado = equipaFaz().filter((o: IEquipamento) => {
            if (o.estado !== "-1") {
                if (
                    o.ultima_operacao.motorON &&
                    atual - new Date(o.ultima_operacao?.timestamp).getTime() <
                        360000
                ) {
                    if (o.ultima_operacao.velocidade !== undefined) {
                        return o.ultima_operacao.velocidade === 0;
                    } else {
                        return false;
                    }
                }
            }
        });
        return {
            andando: ope.length,
            parado: opeParado.length,
            semInfo: equipaFaz().filter((l: IEquipamento) => {
                return (
                    l.estado !== "-1" &&
                    atual - new Date(l.ultima_operacao?.timestamp).getTime() >
                        360000
                );
            }).length,
        };
    }, [equipaFaz]);

    useEffect(() => {
        if (polylineFiltro.length > 0) {
            let cores = polylineFiltro.map((c: any) => {
                let eKey: IEquipamento =
                    equipamentos[
                        equipamentos.findIndex(
                            (e: IEquipamento) => e.id_equipamento === c.id
                        )
                    ];
                return {
                    nome: eKey.descricao,
                    cor: JSON.parse(eKey.estilo).cor,
                    patrimonio: eKey.numero_patrimonio,
                };
            });
            setEquipaCores(cores);
        }
    }, [polylineFiltro]);

    const equipaTalhoes = useCallback(() => {
        if (fazenda.shape !== "") {
            let geoFaz = JSON.parse(fazenda.shape);
            let atual = new Date().getTime();

            let equipa = equipamentos.filter((e: any) => {
                if (
                    e.ultima_operacao !== undefined &&
                    e.estado !== "-1" &&
                    e.ultima_operacao.motorON
                ) {
                    return (
                        e.ultima_operacao.fazenda_temp === fazenda.id_fazenda &&
                        atual -
                            new Date(e.ultima_operacao?.timestamp).getTime() <
                            360000
                    );
                } else {
                    return false;
                }
            });

            let talhaoFin: any;

            let listaTalhao: any = [];
            let talhao: any = [];

            if (talhoesFiltro.length > 0) {
                equipa.forEach((e: IEquipamento) => {
                    if (e.ultima_operacao.talhao_temp) {
                        talhoesFiltro.filter((t: ITalhaoTablet) => {
                            if (t.id_talhao === e.ultima_operacao.talhao_temp) {
                                listaTalhao.push(t);
                            }
                        });
                    } else {
                        let filtroT = talhoesFiltro.map((g: any) => {
                            if (g.coordenadas !== null) {
                                return {
                                    ...g,
                                    coordenadas: {
                                        ...JSON.parse(g.coordenadas),
                                        type: "Polygon",
                                    },
                                };
                            }
                        });
                        filtroT.forEach((f: any) => {
                            if (
                                geoContains(f.coordenadas, [
                                    Number(e.ultima_operacao.longitude),
                                    Number(e.ultima_operacao.latitude),
                                ])
                            ) {
                                listaTalhao.push(f);
                            }
                        });
                    }
                });
            } else {
                geoFaz.features.filter((g: any) => {
                    return equipa.forEach((e: IEquipamento) => {
                        if (
                            geoContains(g.coordenadas, [
                                Number(e.ultima_operacao.longitude),
                                Number(e.ultima_operacao.latitude),
                            ])
                        ) {
                            talhao.push(g);
                        }
                    });
                });
            }

            if (listaTalhao.length > 0) {
                talhaoFin = Array.from(
                    new Set(listaTalhao.map((t: any) => t.nome_talhao))
                ).map((nome: any, i: any, a: any) => {
                    return i === a.length - 1 ? nome : nome + ", ";
                });
                // talhaoFin = listaTalhao.map((t: any, i: any, a: any) => {
                //     if (a.length - 1 === i) {
                //         return t.nome_talhao;
                //     } else {
                //         return t.nome_talhao + ", ";
                //     }
                // });
                return talhaoFin;
            } else if (talhao.length > 0) {
                talhaoFin = talhao.map((t: any, i: any, a: any) => {
                    if (a.length - 1 === i) {
                        return t.properties[JSON.parse(fazenda.campo).nome];
                    } else {
                        return (
                            t.properties[JSON.parse(fazenda.campo).nome] + ", "
                        );
                    }
                });
                return talhaoFin;
            } else {
                return "Nenhum talhão.";
            }
        }
    }, [fazenda, equipamentos, talhoesFiltro]);

    // useEffect(() => {
    //     props.setRefresher(uuidv4());
    // }, [equipaTalhoes]);

    useEffect(() => {
        const handleResize = () => {
            const newScreen =
                window.screen.width < 991.98 &&
                window.innerWidth > window.innerHeight;
            setScreenValida(newScreen);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const newOrientation = window.innerWidth > window.innerHeight;
            setOrientation(newOrientation);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (!processo.side?.ativo) {
        switch (foco.view) {
            case 4:
                return (
                    <EstiloLegenda>
                        <LegendaItem className="hidden-item">
                            <LegendaTitulo>
                                <i
                                    className="fas fa-power-off"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                ></i>
                                Equipamentos por status:
                            </LegendaTitulo>
                            <LegendaConteudo>
                                <div>Ligado:</div>
                                <div>{status().ligado}</div>
                            </LegendaConteudo>
                            <LegendaConteudo>
                                <div>Desligado:</div>
                                <div>{status().desligado}</div>
                            </LegendaConteudo>
                            <LegendaConteudo>
                                <div>Sem informação atual:</div>
                                <div>{status().semInfo}</div>
                            </LegendaConteudo>
                        </LegendaItem>{" "}
                        <LegendaItem className="hidden-item">
                            {" "}
                            <LegendaTitulo>
                                <i
                                    className="fas fa-info-circle"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                ></i>
                                Equipamentos por estado:
                            </LegendaTitulo>
                            <LegendaConteudo>
                                <div>Ativos:</div>
                                <div>{estado().ativo}</div>
                            </LegendaConteudo>
                            <LegendaConteudo>
                                <div>Inativos:</div>
                                <div>{estado().inativo}</div>
                            </LegendaConteudo>
                            <LegendaConteudo>
                                <div>Em manutenção:</div>
                                <div>{estado().manutencao}</div>
                            </LegendaConteudo>
                        </LegendaItem>{" "}
                        <LegendaItem className="hidden-item">
                            <LegendaTitulo>
                                <i
                                    className="fas fa-tachometer-alt"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                ></i>
                                Equipamentos em operação:
                            </LegendaTitulo>
                            {operando().andando > 0 ? (
                                <LegendaConteudo>
                                    <div>Equipamento(s) em operação:</div>
                                    <div>{operando().andando} </div>
                                </LegendaConteudo>
                            ) : (
                                <LegendaConteudo>
                                    <div>Nenhum equipamento em operação.</div>
                                </LegendaConteudo>
                            )}

                            {/* <LegendaConteudo>
                                <div>Equipamento(s) parado(s):</div>
                                <div>{operando().parado}</div>
                            </LegendaConteudo>
                            <LegendaConteudo>
                                <div>Equipamento(s) em histórico:</div>
                                <div>{operando().semInfo}</div>
                            </LegendaConteudo> */}
                        </LegendaItem>
                        <LegendaItem className="hidden-item">
                            <LegendaTitulo>
                                <i
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    className="fas fa-layer-group"
                                ></i>
                                Talhões:
                            </LegendaTitulo>
                            <LegendaConteudo>{equipaTalhoes()}</LegendaConteudo>
                        </LegendaItem>
                        <LegendaItem>
                            <LegendaTitulo>
                                <i
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    className="fas fa-question-circle"
                                ></i>
                                Legenda de cores:
                            </LegendaTitulo>
                            <LegendaConteudo>
                                {screenValida ? (
                                    <>
                                        <div
                                            style={{
                                                width: "calc(100% - 40px)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <ContainerBola>
                                                <Bola color="green" />
                                                Ligado
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="#ff5e00" />
                                                Em Histórico
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="red" />
                                                Sem Sinal
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="#a8a4a42e" />
                                                Desligado
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="yellow" />
                                                Parado
                                            </ContainerBola>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                width: "110px",
                                                marginRight: "13px",
                                            }}
                                        >
                                            <ContainerBola>
                                                <Bola color="green" />
                                                Ligado
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="#ff5e00" />
                                                Em Histórico
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="red" />
                                                Sem Sinal
                                            </ContainerBola>
                                        </div>
                                        <div style={{}}>
                                            <ContainerBola>
                                                <Bola color="#a8a4a42e" />
                                                Desligado
                                            </ContainerBola>
                                            <ContainerBola>
                                                <Bola color="yellow" />
                                                Parado
                                            </ContainerBola>
                                        </div>
                                    </>
                                )}
                            </LegendaConteudo>
                        </LegendaItem>
                    </EstiloLegenda>
                );

            case 7:
                if (polylineFiltro.length > 0) {
                    return (
                        <EstiloLegenda>
                            <LegendaItem>
                                <div
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Equipamentos por cor:
                                </div>
                                <LegendaCoresConteudo>
                                    {itensVisiveis.map((e: any) => {
                                        return (
                                            <LegendaCoresItem>
                                                <div
                                                    style={{
                                                        borderRadius: "50%",
                                                        backgroundColor: e.cor,
                                                        width: "10px",
                                                        height: "10px",
                                                    }}
                                                ></div>
                                                <Tooltip
                                                    title={
                                                        <>
                                                            {e.patrimonio} -{" "}
                                                            {e.nome}
                                                        </>
                                                    }
                                                    trigger={[
                                                        "hover",
                                                        "focus",
                                                        "click",
                                                    ]}
                                                >
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            textOverflow:
                                                                "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {e.patrimonio} -{" "}
                                                        {e.nome}
                                                    </div>
                                                </Tooltip>
                                            </LegendaCoresItem>
                                        );
                                    })}
                                    {!orientation &&
                                        equipaCores.length >
                                            MAX_VISIBLE_ITEMS && (
                                            <Popover
                                                overlayClassName="extra-itens"
                                                trigger="click"
                                                visible={expandido}
                                                color="rgba(0, 0, 0, 0.9)"
                                                overlayStyle={{
                                                    left: "5px",
                                                    padding: "0px 5px",
                                                }}
                                                content={equipaCores
                                                    .slice(MAX_VISIBLE_ITEMS)
                                                    .map((e: any) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    gap: "5px",
                                                                    alignItems:
                                                                        "baseline",
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderRadius:
                                                                            "50%",
                                                                        backgroundColor:
                                                                            e.cor,
                                                                        width: "10px",
                                                                        height: "10px",
                                                                    }}
                                                                ></div>
                                                                <div>
                                                                    {
                                                                        e.patrimonio
                                                                    }{" "}
                                                                    - {e.nome}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            >
                                                <MostrarMaisButton
                                                    onClick={handleMostrarMais}
                                                >
                                                    {expandido
                                                        ? "Mostrar menos"
                                                        : `+${
                                                              equipaCores.length -
                                                              MAX_VISIBLE_ITEMS
                                                          } `}
                                                </MostrarMaisButton>
                                            </Popover>
                                        )}
                                </LegendaCoresConteudo>
                            </LegendaItem>
                        </EstiloLegenda>
                    );
                }
                return <></>;

            default:
                break;
        }
    }

    return <></>;
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        foco: store.mapaFoco,
        polylineFiltro: store.polylineFiltro,
        processo: store.processo,
        fazenda: store.fazenda,

        talhoesFiltro: store.talhaoFiltro,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Legenda);
