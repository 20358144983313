import { SET_CONFIG_SAFRA, SET_LIST_CONFIG_SAFRA } from "../store/actionTypes";
import { ITalhaoTablet } from "../store/types";
import { ICulturaCliente } from "./CulturaReducer";
import { ISafraCliente } from "./SafraReducer";

export interface IConfigSafraModule {
    id_configuracao_safra?: string;
    data_inicial_manejo?: string;
    data_final_manejo?: string;
    data_inicial_colheita?: string;
    data_final_colheita?: string;
    talhao_temp?: ITalhaoTablet;
    safra_temp?: ISafraCliente;
    cultura_temp?: ICulturaCliente;
}

export type IConfigSafra = IConfigSafraModule;
const ConfigSafra: IConfigSafra = {};

export type IConfigSafraList = IConfigSafraModule[];
const ConfigSafraList: IConfigSafraList = [];

export interface IConfigSafraReducer {
    type: any;
    payload: IConfigSafra;
}

export const configSafraReducer = (
    state = ConfigSafra,
    action: IConfigSafraReducer
) => {
    switch (action.type) {
        case SET_CONFIG_SAFRA:
            return action.payload;
        default:
            return state;
    }
};

export interface IConfigSafraListReducer {
    type: any;
    payload: IConfigSafraList;
}

export const configSafraListReducer = (
    state = ConfigSafraList,
    action: IConfigSafraListReducer
) => {
    switch (action.type) {
        case SET_LIST_CONFIG_SAFRA:
            return action.payload;
        default:
            return state;
    }
};
