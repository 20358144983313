import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { IStatusList, ITalhaoTablet } from "../../../../store/types";
import { useEffect, useState } from "react";
import { ICulturaClienteList } from "../../../../reducers/CulturaReducer";
import {
    ISafraCliente,
    ISafraClienteList,
} from "../../../../reducers/SafraReducer";
import { adicionarConfigSafra } from "../../../../store/configSafraActions";
import { setStatusNull } from "../../../../store/actions";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IAdicionarConfigSafraProps {
    setModal: any;
    fazendas: IFazendaList;
    talhoes: ITalhaoTablet[];

    culturaCList: ICulturaClienteList;
    safraCList: ISafraClienteList;

    status: IStatusList;

    adicionarConfigSafra: Function;
    setStatusNull: any;
}

function AdicionarConfigSafra(props: IAdicionarConfigSafraProps) {
    const {
        fazendas,
        talhoes,
        culturaCList,
        safraCList,
        adicionarConfigSafra,
        status,
        setStatusNull,
    } = props;

    const [filterTalhoes, setFilterTalhoes] = useState(talhoes);
    const [fazLocal, setFazLocal] = useState("");
    const [talhoesLocal, setTalhoesLocal] = useState([]);
    const [disabledTalhoes, setDisableTalhoes] = useState(false);

    const [datasM, setDatasM] = useState<any>(["", ""]);
    const [datasC, setDatasC] = useState<any>(["", ""]);

    const [form] = Form.useForm();

    useEffect(() => {
        let filteredTal: any = [];
        if (fazLocal !== "") {
            talhoes.filter((t: any) => {
                if (t.fazenda_temp === fazLocal) {
                    return filteredTal.push(t);
                }
            });
        } else {
            filteredTal = talhoes;
        }
        setFilterTalhoes(filteredTal);
    }, [fazLocal, talhoes]);

    useEffect(() => {
        if (status.configSafra?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "configSafra");
        }
    });

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(v: any) => {
                let dataIManejo: any = new Date(v.manejo[0])
                    .toISOString()
                    .split(".")[0];
                let dataFManejo: any = new Date(v.manejo[1])
                    .toISOString()
                    .split(".")[0];
                let dataIColheita: any = new Date(v.colheita[0])
                    .toISOString()
                    .split(".")[0];
                let dataFColheita: any = new Date(v.colheita[1])
                    .toISOString()
                    .split(".")[0];
                let allTalhoes = v.talhao_temp.some(
                    (t: any) => t.label === "Todos"
                );
                if (allTalhoes) {
                    filterTalhoes.forEach((item: any) => {
                        let obj = {
                            data_inicial_manejo:
                                dataIManejo.split("T")[0] + "T00:00:00",
                            data_final_manejo:
                                dataFManejo.split("T")[0] + "T23:59:59",
                            data_inicial_colheita:
                                dataIColheita.split("T")[0] + "T00:00:00",
                            data_final_colheita:
                                dataFColheita.split("T")[0] + "T23:59:59",
                            talhao_temp: item.id_talhao,
                            safra_temp: v.safra_temp,
                            cultura_temp: v.cultura_temp,
                        };
                        adicionarConfigSafra(obj);
                    });
                } else {
                    v.talhao_temp.forEach((item: any) => {
                        let obj = {
                            data_inicial_manejo:
                                dataIManejo.split("T")[0] + "T00:00:00",
                            data_final_manejo:
                                dataFManejo.split("T")[0] + "T23:59:59",
                            data_inicial_colheita:
                                dataIColheita.split("T")[0] + "T00:00:00",
                            data_final_colheita:
                                dataFColheita.split("T")[0] + "T23:59:59",
                            talhao_temp: item.key,
                            safra_temp: v.safra_temp,
                            cultura_temp: v.cultura_temp,
                        };
                        adicionarConfigSafra(obj);
                    });
                }
            }}
        >
            <Row gutter={[24, 0]}>
                <Col span={6}>
                    <Form.Item
                        label="Safra:"
                        name="safra_temp"
                        id="safra_temp"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma safra",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select placeholder="Selecione">
                            {" "}
                            {safraCList.map((s: any) => {
                                return (
                                    <Option
                                        value={s.safra_temp}
                                        key={s.safra_temp}
                                    >
                                        {s.descricao}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    {" "}
                    <Form.Item
                        label="Cultura:"
                        name="cultura_temp"
                        id="cultura_temp"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma cultura",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select placeholder="Selecione">
                            {culturaCList.map((c: any) => {
                                return (
                                    <Option
                                        value={c.cultura_temp}
                                        key={c.cultura_temp}
                                    >
                                        {c.nomeCultura}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Fazenda:"
                        name="FazendaForm"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma fazenda",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            placeholder="Selecione"
                            onSelect={(value: any) => {
                                setTalhoesLocal([]);
                                // form.setFieldsValue({ talhao_temp: "" });
                                setFazLocal(value);
                            }}
                            onChange={() => {
                                setTalhoesLocal([]);
                                // form.setFieldsValue({ talhao_temp: "" });
                            }}
                        >
                            {fazendas.map((f: any) => {
                                return (
                                    <Option
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                );
                            })}{" "}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    {" "}
                    <Form.Item
                        label="Talhão:"
                        name="talhao_temp"
                        id="talhao_temp"
                        rules={[
                            {
                                required: true,
                                message: "Selecione ao menos uma opção",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            disabled={fazLocal === ""}
                            mode="multiple"
                            placeholder="Selecione"
                            allowClear={false}
                            dropdownStyle={{ zIndex: 1090 }}
                            showSearch
                            filterOption={(input, option: any) => {
                                let condicao = false;
                                if (option.value !== 0 && option.children) {
                                    return option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                        ? (condicao = true)
                                        : (condicao = false);
                                } else if (option.label) {
                                    return option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                        ? (condicao = true)
                                        : (condicao = false);
                                }

                                return condicao;
                            }}
                            labelInValue={true}
                            value={talhoesLocal}
                            onChange={(e: any) => {
                                setTalhoesLocal(e);
                            }}
                            onSelect={(value: any, option: any) => {
                                if (value.value === "") {
                                    setTalhoesLocal([]);
                                    setDisableTalhoes(true);
                                }
                            }}
                            onDeselect={(value: any, option: any) => {
                                if (value.value === "") {
                                    setDisableTalhoes(false);
                                }
                            }}
                        >
                            {" "}
                            <Option
                                value=""
                                style={{
                                    fontWeight: "bold",
                                }}
                                disabled={talhoesLocal.length > 0}
                            >
                                Todos
                            </Option>
                            {filterTalhoes
                                .sort((a: any, b: any) => {
                                    return a.nome_talhao - b.nome_talhao;
                                })
                                .map((e: any) => {
                                    return (
                                        <Option
                                            key={e.id_talhao}
                                            value={e.id_talhao}
                                            disabled={disabledTalhoes}
                                        >
                                            {e.nome_talhao}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="Periodo de Manejo:"
                        name="manejo"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma data",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <RangePicker
                            locale={locale}
                            disabledDate={current => {
                                // Can not select days after today and before start Date
                                const start = moment(
                                    "2020-01-01",
                                    "YYYY-MM-DD"
                                );

                                return current < start;
                            }}
                            // onCalendarChange={(val: any) => {
                            //     setValue(val);
                            // }}
                            format="DD/MM/yyyy"
                            onChange={(val: any, val2: any) => {
                                let tzoffset =
                                    new Date().getTimezoneOffset() * 60000;

                                let datas: any = [];
                                if (val2[0] !== "" && val2[1] !== "") {
                                    val2.map((v: any) => {
                                        const [dateString] = v.split(" ");
                                        const [day, month, year] =
                                            dateString.split("/");

                                        const dateObj: any = new Date(
                                            +year,
                                            +month - 1,
                                            +day
                                        );
                                        datas.push(
                                            new Date(dateObj - tzoffset)
                                                .toISOString()
                                                .split(".")[0]
                                        );
                                    });
                                }
                                setDatasM(datas);
                            }}
                            style={{
                                color: "black",
                                width: "100%",
                            }}
                            placeholder={["Data inicial", "Data final"]}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Periodo de Colheita:"
                        name="colheita"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma data",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <RangePicker
                            locale={locale}
                            disabledDate={current => {
                                // Can not select days after today and before start Date
                                const start = moment(
                                    "2020-01-01",
                                    "YYYY-MM-DD"
                                );

                                return current < start;
                            }}
                            // onCalendarChange={(val: any) => {
                            //     setValue(val);
                            // }}
                            format="DD/MM/yyyy"
                            onChange={(val: any, val2: any) => {
                                let tzoffset =
                                    new Date().getTimezoneOffset() * 60000;

                                let datas: any = [];
                                if (val2[0] !== "" && val2[1] !== "") {
                                    val2.map((v: any) => {
                                        const [dateString] = v.split(" ");
                                        const [day, month, year] =
                                            dateString.split("/");

                                        const dateObj: any = new Date(
                                            +year,
                                            +month - 1,
                                            +day
                                        );
                                        datas.push(
                                            new Date(dateObj - tzoffset)
                                                .toISOString()
                                                .split(".")[0]
                                        );
                                    });
                                }
                                setDatasC(datas);
                            }}
                            style={{
                                color: "black",
                                width: "100%",
                            }}
                            placeholder={["Data inicial", "Data final"]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    // disabled={handleDisable()}
                    loading={status.configSafra?.add?.loading}
                    block
                >
                    Salvar Configuração
                </Button>
            </div>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        talhoes: store.talhoesTablet,

        culturaCList: store.culturasCliente,
        safraCList: store.safrasCliente,

        status: store.loader,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarConfigSafra,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdicionarConfigSafra);
