import { createStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import { authReducer, AuthState } from "../reducers/AuthReducer";
import {
    createTransform,
    persistCombineReducers,
    persistStore,
} from "redux-persist";
import { JsogService } from "jsog-typescript";
import localforage from "localforage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import createCompressor from "redux-persist-transform-compress";
import {
    IKeepAlive,
    keepAliveReducer,
    StatusReducer,
} from "../reducers/StatusReducer";
import {
    antenaListConfigReducer,
    antenaListReducer,
    antenaReducer,
    IAntena,
    IAntenaList,
} from "../reducers/AntenaReducer";
import {
    culturaReducer,
    culturaListReducer,
    ICultura,
    ICulturaList,
    ICulturaCliente,
    ICulturaClienteList,
    culturaClienteReducer,
    culturaClienteListReducer,
} from "../reducers/CulturaReducer";
import {
    attrEquipamento,
    equipamentoListReducer,
    equipamentoReducer,
    IAttrEquipamento,
    IEquipamento,
    IEquipamentoList,
} from "../reducers/EquipamentoReducer";
import {
    fazendaListReducer,
    fazendaReducer,
    IFazenda,
    IFazendaList,
    IFazendaModule,
    ITalhao,
    talhaoReducer,
} from "../reducers/FazendaReducer";
import {
    equipaPolygonReducer,
    mapCReducer,
    mapFocoReducer,
    mapReducer,
    overlayEquipaReducer,
} from "../reducers/MapReducer";
import {
    IMarkerAntena,
    IMarkers,
    IMarkersAll,
    INotifys,
    markerAntenaReducer,
    markersAllReducer,
    markersAtivosReducer,
    notifysAtivosReducer,
    flagMarkerReducer,
    IFlagMarker,
    IParadas,
    paradasReducer,
} from "../reducers/MarkerReducer";
import {
    IMensagemList,
    mensagemListReducer,
} from "../reducers/MensagemReducer";
import { IMenu, menuReducer } from "../reducers/MenuReducer";
import {
    IColumn,
    IOperacao,
    IOperacoes,
    opColunasReducer,
    operacaoFilterReducer,
    operacaoListReducer,
    operacaoReducer,
    opBBoxReducer,
    IOpBBox,
    IOperacoesFilter,
    IRegistroOpe,
    registroOpeReducer,
} from "../reducers/OperacaoReducer";
import {
    processReducer,
    processListReducer,
    IProcess,
    IProcessList,
    dashboardToggleReducer,
} from "../reducers/ProcessReducer";
import {
    ISafra,
    ISafraCliente,
    ISafraClienteList,
    ISafraList,
    safraClienteListReducer,
    safraClienteReducer,
    safraListReducer,
    safraReducer,
} from "../reducers/SafraReducer";
import { ITime, timeReducer } from "../reducers/TimeReducer";
import {
    ITipoOp,
    ITipoOpCliente,
    ITipoOpClienteList,
    ITipoOpList,
    tipoOpClienteListReducer,
    tipoOpClienteReducer,
    tipoOpListReducer,
    tipoOpParadaReducer,
    tipoOpReducer,
} from "../reducers/TipoOpReducer";
import {
    IPolylineFiltro,
    IPolylineList,
    IPolylineRender,
    polylineFiltroReducer,
    polylineFiltroRenderReducer,
    polylineListReducer,
} from "../reducers/PolylineReducer";
import {
    IPreferencia,
    preferenciaReducer,
} from "../reducers/PreferenciaReducer";
import {
    IFoco,
    IMapCoord,
    IMapRender,
    IStatusList,
    ITalhaoTablet,
} from "./types";
import {
    IServidor,
    IServidorList,
    servidorListReducer,
    servidorReducer,
} from "../reducers/ServidorReducer";
import {
    IOperacional,
    IRelatorio,
    ITabOperacional,
    operacionalReducer,
    relOneReducer,
    relatorioReducer,
    tabOperacionalReducer,
} from "../reducers/RelatorioReducer";
import {
    IOperador,
    IOperadorList,
    operadorListReducer,
    operadorReducer,
} from "../reducers/OperadorReducer";
import { ITheme, themeReducer } from "../reducers/ThemeReducer";
import {
    ITipoParada,
    ITipoParadaList,
    tipoParadaListReducer,
    tipoParadaReducer,
} from "../reducers/TipoParadaReducer";
import {
    alertaListReducer,
    alertaReducer,
    alertaRenderReducer,
    alertaUserListReducer,
    alertaUserReducer,
    IAlerta,
    IAlertaList,
    IAlertaRender,
    IAlertaUser,
    IAlertaUserList,
} from "../reducers/AlertaReducer";
import {
    IUser,
    IUserList,
    userListReducer,
    userReducer,
} from "../reducers/UserReducer";
import {
    talhaoFiltroReducer,
    talhaoListTabletReducer,
    talhaoTabletReducer,
} from "../reducers/TalhaoTabletReducer";
import {
    attrImplemento,
    IAttrImplemento,
    IImplemento,
    IImplementoList,
    implementoListReducer,
    implementoReducer,
} from "../reducers/ImplementoReducer";
import {
    IParametro,
    IParametroList,
    parametroListReducer,
    parametroReducer,
} from "../reducers/ParametroReducer";
import {
    configSafraListReducer,
    configSafraReducer,
    IConfigSafra,
    IConfigSafraList,
} from "../reducers/ConfigSafraReducer";

const jsog = new JsogService();

// const rootReducer = combineReducers<any>({
//     auth: authReducer
// });

export const JSOGTransform = createTransform(
    (inboundState, key) => jsog.serialize(inboundState),
    (outboundState, key) => jsog.deserialize(outboundState)
);

const compressor = createCompressor();

const persistConfig = {
    key: "root",
    storage: localforage,
    transforms: [compressor],
    blacklist: [
        "loader",
        "alerta",
        "alertas",
        "alertaRender",
        "alertaUser",
        "alertasUser",
        "antena",
        "antenas",
        "antenasConfig",
        "antenaMarker",
        "attrEquipamento",
        "attrImplemento",
        "configSafra",
        "configSafras",
        "cultura",
        "culturaCLiente",
        "culturasCliente",
        "equipamento",
        "equipaPolygon",
        "equipaOverlay",
        "fazenda",
        "flagPolyline",
        "implemento",
        "implementos",
        "keepAlive",
        "mapa",
        "mapaCoord",
        "mapaFoco",
        "marker",
        "allMarkers",
        "menu",
        "notify",
        "opColunas",
        "operador",
        "operadores",
        "operacao",
        "operacoes",
        "operacaoBBox",
        "operacional",
        "opFiltro",
        "paradas",
        "parametro",
        "parametros",
        "polylines",
        "polylineFiltro",
        "polylineRender",
        "preferencia",
        "processo",
        "processos",
        "registroOpe",
        "relatorio",
        "relOne",
        "safra",
        "safraCLiente",
        "safrasCliente",
        "servidor",
        "servidores",
        "tabOperacional",
        "talhao",
        "talhaoFiltro",
        "talhaoTablet",
        "talhoesTablet",
        "time",
        "tipoOp",
        "tiposOp",
        "tipoOpCliente",
        "tiposOpCliente",
        "tipoOpParada",
        "tipoParada",
        "tipoParadas",
        "theme",

        "usuario",
        "usuarios",
    ],
    stateReconciler: autoMergeLevel2,
};

export interface IStore {
    loader: IStatusList;

    alerta: IAlerta;
    alertas: IAlertaList;
    alertaRender: IAlertaRender;
    alertaUser: IAlertaUser;
    alertasUser: IAlertaUserList;
    allMarkers: IMarkersAll;
    antena: IAntena;
    antenas: IAntenaList;
    antenasConfig: IAntenaList;
    antenaMarker: IMarkerAntena;
    attrEquipamento: IAttrEquipamento;
    attrImplemento: IAttrImplemento;
    auth: AuthState;
    configSafra: IConfigSafra;
    configSafras: IConfigSafraList;
    cultura: ICultura;
    culturas: ICulturaList;
    culturaCliente: ICulturaCliente;
    culturasCliente: ICulturaClienteList;
    dashboard: string;
    equipamento: IEquipamento;
    equipamentos: IEquipamentoList;
    relatorio: IRelatorio;
    //equipaPolygon
    equipaOverlay: any;
    fazenda: IFazenda | IFazendaModule;
    fazendas: IFazendaList;
    flagPolyline: IFlagMarker;
    implemento: IImplemento;
    implementos: IImplementoList;
    keepAlive: IKeepAlive;
    mapa: IMapRender;
    mapaCoord: IMapCoord;
    mapaFoco: IFoco;
    marker: IMarkers;
    mensagens: IMensagemList;
    menu: IMenu;
    notify: INotifys;
    opColunas: IColumn;
    operador: IOperador;
    operadores: IOperadorList;
    operacao: IOperacao;
    operacoes: IOperacoes;
    operacaoBBox: IOpBBox;
    operacional: IOperacional;
    opFiltro: IOperacoesFilter;
    paradas: IParadas;
    parametro: IParametro;
    parametros: IParametroList;
    preferencia: IPreferencia;
    polylines: IPolylineList;
    polylineFiltro: IPolylineFiltro;
    polylineRender: IPolylineRender;
    processo: IProcess;
    processos: IProcessList;
    registroOpe: IRegistroOpe;
    relOne: IRelatorio;
    safra: ISafra;
    safras: ISafraList;
    safraCliente: ISafraCliente;
    safrasCliente: ISafraClienteList;
    servidor: IServidor;
    servidores: IServidorList;
    tabOperacional: ITabOperacional;
    talhao: ITalhao;
    talhaoFiltro: ITalhaoTablet[];
    talhaoTablet: ITalhaoTablet;
    talhoesTablet: ITalhaoTablet[];
    time: ITime;
    tipoOp: ITipoOp;
    tiposOp: ITipoOpList;
    tipoOpCliente: ITipoOpCliente;
    tiposOpCliente: ITipoOpClienteList;
    tipoOpParada: ITipoOp;
    tipoParada: ITipoParada;
    tipoParadas: ITipoParadaList;
    theme: ITheme;
    usuario: IUser;
    usuarios: IUserList;
}

const persistReducer = persistCombineReducers(persistConfig, {
    //States de Status
    loader: StatusReducer,

    //States de Sistema
    alerta: alertaReducer,
    alertas: alertaListReducer,
    alertaRender: alertaRenderReducer,
    alertaUser: alertaUserReducer,
    alertasUser: alertaUserListReducer,
    allMarkers: markersAllReducer,
    antena: antenaReducer,
    antenaMarker: markerAntenaReducer,
    antenas: antenaListReducer,
    antenasConfig: antenaListConfigReducer,
    attrEquipamento: attrEquipamento,
    attrImplemento: attrImplemento,
    auth: authReducer,
    configSafra: configSafraReducer,
    configSafras: configSafraListReducer,
    cultura: culturaReducer,
    culturas: culturaListReducer,
    culturaCliente: culturaClienteReducer,
    culturasCliente: culturaClienteListReducer,
    dashboard: dashboardToggleReducer,
    equipamento: equipamentoReducer,
    equipamentos: equipamentoListReducer,
    equipaPolygon: equipaPolygonReducer,
    equipaOverlay: overlayEquipaReducer,
    fazenda: fazendaReducer,
    fazendas: fazendaListReducer,
    flagPolyline: flagMarkerReducer,
    implemento: implementoReducer,
    implementos: implementoListReducer,
    keepAlive: keepAliveReducer,
    mapa: mapReducer,
    mapaCoord: mapCReducer,
    mapaFoco: mapFocoReducer,
    marker: markersAtivosReducer,
    mensagens: mensagemListReducer,
    menu: menuReducer,
    notify: notifysAtivosReducer,
    opColunas: opColunasReducer,
    operador: operadorReducer,
    operadores: operadorListReducer,
    operacao: operacaoReducer,
    operacoes: operacaoListReducer,
    operacaoBBox: opBBoxReducer,
    operacional: operacionalReducer,
    opFiltro: operacaoFilterReducer,
    paradas: paradasReducer,
    parametro: parametroReducer,
    parametros: parametroListReducer,
    preferencia: preferenciaReducer,
    polylines: polylineListReducer,
    polylineFiltro: polylineFiltroReducer,
    polylineRender: polylineFiltroRenderReducer,
    processo: processReducer,
    processos: processListReducer,
    registroOpe: registroOpeReducer,
    relatorio: relatorioReducer,
    relOne: relOneReducer,
    safra: safraReducer,
    safras: safraListReducer,
    safraCliente: safraClienteReducer,
    safrasCliente: safraClienteListReducer,
    servidor: servidorReducer,
    servidores: servidorListReducer,
    tabOperacional: tabOperacionalReducer,
    talhao: talhaoReducer,
    talhaoFiltro: talhaoFiltroReducer,
    talhaoTablet: talhaoTabletReducer,
    talhoesTablet: talhaoListTabletReducer,
    time: timeReducer,
    tipoOp: tipoOpReducer,
    tiposOp: tipoOpListReducer,
    tipoOpCliente: tipoOpClienteReducer,
    tiposOpCliente: tipoOpClienteListReducer,
    tipoOpParada: tipoOpParadaReducer,
    tipoParada: tipoParadaReducer,
    tipoParadas: tipoParadaListReducer,
    theme: themeReducer,
    usuario: userReducer,
    usuarios: userListReducer,
});

const enHanceCreateStore = composeWithDevTools(applyMiddleware(thunk))(
    createStore
);

export const Store = enHanceCreateStore(persistReducer);

export const persistor = persistStore(Store);
